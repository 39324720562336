import React, { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import Pagination from "react-js-pagination";
import DotLoader from "../../hoc/DotLoader";
import { getContactList } from "../../redux/actions";
import { useHistory } from "react-router-dom";
import { SearchField } from "../../hoc";
import { CONSTANTS } from "../../config";
import qs from "qs";
import AddContact from "./AddContact";
import EditContact from "./EditContact";
import ViewUpdatedHistory from "./ViewUpdatedHistory";
import "./Contact.css";
import { Modal as AntModal, DatePicker, Select } from "antd";
import Modal from "react-bootstrap/Modal";
import _ from "lodash";
import {
  assignContactToEmployee,
  exportContactExcel,
} from "../../config/services/employeeServices";
import { deleteContactByAdmin } from "../../redux/actions/deleteContact";
import DOMPurify from "dompurify";
import ResponseFilter from "../../ResponseFilter";
import { useDebounce } from "../attendance/Constants";
import moment from "moment";
import { disabledEndDate, statusMon } from "../leaves/Constants";
import {
  addedByValue,
  contactResultValue,
  contactStatusValue,
} from "./ContactConstants";
const { RangePicker } = DatePicker;

let SORT_TYPE_CONSTANTS = CONSTANTS.SORT_TYPE_CONSTANTS;
let arrayOfYear = [];
const currentYear = new Date().getFullYear();
for (let i = 0; i <= 3; i++) {
  arrayOfYear.push(currentYear - i);
}
const arrayOfMonths = CONSTANTS.TRANSACTION_LIST_CONSTANTS.ARRAY_OF_MONTHS;

const Contact = ({
  dataCount,
  getContactList,
  contactList,
  salesList,
  deleteContactByAdmin,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [showEditModal, setEditShowModal] = useState(false);
  const [updateContactdata, setUpdateContactdata] = useState({});
  const currentMonth = new Date().getMonth() + 1;
  const [search, setsearch] = useState("");
  const [searchYear, setSearchYear] = useState(new Date().getFullYear());
  const [searchMonth, setSearchMonth] = useState(new Date().getMonth() + 1);
  const [AddedBy, setAddedBy] = useState("");
  const [pageNo, setPageNo] = useState(1);
  const [sortType, setSortType] = useState(2);
  const [sortField, setSortField] = useState("created");
  const [projectDescriptionModal, setProjectDescriptionModal] = useState(false);
  const [projectDescriptionDeatail, setProjectDescriptionDetail] = useState("");
  const [assignContactModal, setAssignContactModal] = useState(false);
  const [employeeId, setEmployeeId] = useState("");
  const [employeeIdErrorMsg, setEmployeeIdErrorMsg] = useState("");
  const [contactId, setContactId] = useState("");
  const [resMsg, setResMsg] = useState(false);
  const [resDeleteMsg, setResDeleteMsg] = useState(false);

  const [resFailMsg, setResFailMsg] = useState(false);
  const [miniLoader, setMiniLoader] = useState(false);
  const [deleteContactId, setDeleteContactId] = useState("");
  const [openDeleteContactPopup, setOpenDeleteContactPopup] = useState(false);
  const [commentModal, setCommentModal] = useState(false);
  const [comment, setComment] = useState("");
  const [startDateStamp, setStartDateStamp] = useState("");
  const [endDateStamp, setEndDateStamp] = useState("");
  const [startDateRange, setStartDateRange] = useState("");
  const [endDateRange, setEndDateRange] = useState("");
  const [showViewUpdatedHistoryModal, setShowViewUpdatedHistoryModal] = useState(false);
  const [updatedHistory, setUpdatedHistory] = useState({})
  const history = useHistory();
  const { Option } = Select;

  const count = 10;
  const debouncedSearchTerm = useDebounce(search, 2000);

  useEffect(() => {
    getData();
  }, [
    pageNo,
    debouncedSearchTerm,
    sortType,
    searchMonth,
    searchYear,
    AddedBy,
    startDateRange,
    endDateRange,
    startDateStamp,
    endDateStamp,
  ]);

  useMemo(() => {
    const filterParams = history.location.search.substr(1);
    const filtersFromParams = qs.parse(filterParams);
    const format = "ddd MMM DD YYYY HH:mm:ss [GMT] ZZ";

    setSortType(
      filtersFromParams.sortType
        ? filtersFromParams.sortType
        : SORT_TYPE_CONSTANTS.Descending
    );
    setSortField(
      filtersFromParams.sortField ? filtersFromParams.sortField : "created"
    );
    setPageNo(filtersFromParams.pageNo ? Number(filtersFromParams.pageNo) : 1);
    setsearch(filtersFromParams.search ? filtersFromParams.search : "");
    setSearchYear(
      filtersFromParams.searchYear
        ? filtersFromParams.searchYear
        : new Date().getFullYear()
    );
    setSearchMonth(
      filtersFromParams.searchMonth
        ? Number(filtersFromParams.searchMonth)
        : currentMonth
    );
    setAddedBy(filtersFromParams.filterBy ? filtersFromParams.filterBy : "");
    setStartDateRange(
      filtersFromParams.startDateRange ? filtersFromParams.startDateRange : ""
    );
    setEndDateRange(
      filtersFromParams.endDateRange ? filtersFromParams.endDateRange : ""
    );
    setStartDateStamp(
      filtersFromParams.startDateStamp
        ? moment(filtersFromParams.startDateStamp, format)
        : ""
    );
    setEndDateStamp(
      filtersFromParams.endDateStamp
        ? moment(filtersFromParams.endDateStamp, format)
        : ""
    );
  }, [history.location.search]);

  const getData = async () => {
    const query = `search=${search}&pageNo=${pageNo - 1
      }&count=${count}&sortType=${sortType}&sortField=${sortField}&searchYear=${startDateRange && endDateRange ? "" : searchYear
      }&searchMonth=${startDateRange && endDateRange ? "" : searchMonth
      }&filterBy=${AddedBy}&startDateRange=${startDateRange}&endDateRange=${endDateRange}&startDateStamp=${startDateStamp}&endDateStamp=${endDateStamp}`;
    history.push(
      `contact?search=${search.trim()}&pageNo=${pageNo}&count=${count}&sortType=${sortType}&sortField=${sortField}&searchYear=${startDateRange && endDateRange ? "" : searchYear
      }&searchMonth=${startDateRange && endDateRange ? "" : searchMonth
      }&filterBy=${AddedBy}&startDateRange=${startDateRange}&endDateRange=${endDateRange}&startDateStamp=${startDateStamp}&endDateStamp=${endDateStamp}`
    );
    await getContactList(query);
  };
  const handleassignValidation = () => {
    let validate = true;
    if (employeeId == null || employeeId == undefined || employeeId == "") {
      validate = false;
    } else {
    }
    return validate;
  };
  const handleOpenAddContact = () => {
    setShowModal(true);
  };

  const handleOpenEditClick = (element, e) => {
    setEditShowModal(true);
    setUpdateContactdata(element);
  };

  const handleOpenAssignClick = (element, list, e) => {
    setAssignContactModal(true);
    setContactId(list._id);
  };

  const handlePageChange = (pageNum) => {
    setPageNo(pageNum);
  };
  const handleSort = (e) => {
    setSortField(e);
    if (sortType == SORT_TYPE_CONSTANTS.Asscending) {
      setSortType(SORT_TYPE_CONSTANTS.Descending);
    } else {
      setSortType(SORT_TYPE_CONSTANTS.Asscending);
    }
  };

  const handleChangeYear = (year) => {
    year != undefined
      ? setSearchYear(year)
      : setSearchYear(new Date().getFullYear());
    setPageNo(1);
  };
  const handleChangeMonth = (element) => {
    element != undefined
      ? setSearchMonth(parseInt(element.key) + 1)
      : setSearchMonth(currentMonth);
    setPageNo(1);
  };

  const handleAmountTypeChange = (val) => {
    val != undefined ? setAddedBy(val) : setAddedBy("");
    setPageNo(1);
  };

  const closeModal1 = () => {
    setProjectDescriptionModal(false);
    setAssignContactModal(false);
    setEmployeeId("");
    setContactId("");
    setEmployeeIdErrorMsg("");
    setMiniLoader(false);
    setOpenDeleteContactPopup(false);
    setDeleteContactId("");
    setCommentModal(false);
    setComment(false);
  };
  const handleOpenProjectDescription = (e) => {
    setProjectDescriptionModal(true);
    setProjectDescriptionDetail(e);
  };

  const handleComment = (e) => {
    setCommentModal(true);
    setComment(e);
  };

  const handleSelectEmployeeChange = (e) => {
    const Emp = salesList.filter((ele) => {
      return ele._id === e;
    });
    setEmployeeId(e);
  };
  const handleEmployeeSearch = (e) => {
    console.log("e : ", e);
  };

  const handleUploadButtonClick = async () => {
    if (handleassignValidation(employeeId)) {
      setMiniLoader(true);
      let data = {
        contactId: contactId,
        employeeId: employeeId,
      };
      assignContactToEmployee(data).then((res) => {
        if (res.data.statusCode === 1) {
          getData();
          closeModal1();
          setResMsg(true);
        } else {
          setResFailMsg(true);
        }
      });
    } else {
    }

    setTimeout(() => setResMsg(false), 4000);
    setTimeout(() => setResFailMsg(false), 4000);
  };

  const onSubmit = () => {
    handleUploadButtonClick();
    if (employeeId == null || employeeId == undefined || employeeId == "") {
      setEmployeeIdErrorMsg("Please Select Employee");
    } else {
      setEmployeeIdErrorMsg("");
    }
  };

  const handleOpenDeleteContactPopup = (e) => {
    setOpenDeleteContactPopup(true);
    setDeleteContactId(e);
  };
  const handleViewUpdatedHistory = (history) => {
    console.log(history, "showingthisconstacthistlry")
    setShowViewUpdatedHistoryModal(true)
    setUpdatedHistory(history)
  }
  const handleDeleteContactClick = (e) => {
    const data = {
      data: {
        contactId: deleteContactId,
      },
    };
    setMiniLoader(true);
    deleteContactByAdmin(
      data,
      setResDeleteMsg,
      setResFailMsg,
      getData,
      setMiniLoader,
      closeModal1
    );

    setTimeout(() => setResDeleteMsg(false), 4000);
    setTimeout(() => setResFailMsg(false), 4000);
  };

  function createMarkup(e) {
    return {
      __html: DOMPurify.sanitize(e),
    };
  }
  const handleExcel = () => {
    const query = `search=${search}&sortType=${sortType}&sortField=${sortField}&searchYear=${startDateRange && endDateRange ? "" : searchYear
      }&searchMonth=${startDateRange && endDateRange ? "" : searchMonth
      }&filterBy=${AddedBy}&startDateRange=${startDateRange}&endDateRange=${endDateRange}&startDateStamp=${startDateStamp}&endDateStamp=${endDateStamp}`;
    exportContactExcel(query).then((res) => {
      let { status, resData } = ResponseFilter(res);
      if (status) {
        window.location.href = resData.excelSheet;
      }
    });
  };

  const handleRangePicker = (date) => {
    setPageNo(1);
    if (date.length === 0) {
      setStartDateStamp("");
      setEndDateStamp("");
      setStartDateRange(
        !_.isEmpty(date) ? new Date(date[0]).setHours(6, 30, 0, 0) : ""
      );
      setEndDateRange(
        !_.isEmpty(date) ? new Date(date[1]).setHours(6, 30, 0, 0) : ""
      );
    } else {
      setStartDateStamp(date[0]);
      setEndDateStamp(date[1]);
      setStartDateRange(
        !_.isEmpty(date) ? new Date(date[0]).setHours(6, 30, 0, 0) : ""
      );
      setEndDateRange(
        !_.isEmpty(date) ? new Date(date[1]).setHours(6, 30, 0, 0) : ""
      );
      setSearchYear("");
      setSearchMonth("");
    }
  };

  return (
    <div className="body_container cm_bold marginTop">
      {resMsg ? (
        <div className="alert alert-success cm_top_fix">
          Contact Assign successfully
        </div>
      ) : (
        ""
      )}

      {resDeleteMsg ? (
        <div className="alert alert-success cm_top_fix">
          Contact Delete successfully
        </div>
      ) : (
        ""
      )}
      {resFailMsg ? (
        <div className="alert alert-danger cm_top_fix">Please Try Again</div>
      ) : (
        ""
      )}
      <AddContact
        showModal={showModal}
        setShowModal={setShowModal}
        search={search}
        pageNo={pageNo}
        count={count}
        sortField={sortField}
        sortType={sortType}
        AddedBy={AddedBy}
        searchMonth={searchMonth}
        searchYear={searchYear}
      />

      <EditContact
        showEditModal={showEditModal}
        setEditShowModal={setEditShowModal}
        search={search}
        pageNo={pageNo}
        count={count}
        sortField={sortField}
        sortType={sortType}
        AddedBy={AddedBy}
        searchMonth={searchMonth}
        searchYear={searchYear}
        state={updateContactdata}
      />
      <ViewUpdatedHistory
        showViewUpdatedHistoryModal={showViewUpdatedHistoryModal}
        setShowViewUpdatedHistoryModal={setShowViewUpdatedHistoryModal}
        state={updatedHistory}
      />
      <div className="tableDescDiv">
        <h4> Contact(s) List :</h4>
      </div>

      <div className="filters justify-content-end mb-3">
        <button
          onClick={handleOpenAddContact}
          className="btn btn-primary"
          title="Add Contact"
        >
          + Add Contact
        </button>
        <button
          className="btn btn-primary"
          title="export excel"
          onClick={handleExcel}
        >
          {" "}
          Export Excel
        </button>
      </div>
      <div className="headerDiv">
        <SearchField
          inputValue={search.trim()}
          handleInputChange={setsearch}
          handlePageChange={setPageNo}
          handleClearSearchClick={setsearch}
        />
        <div className="vendorOrder-filterDiv">
          <Select
            className="mr-3"
            style={{ width: "30%" }}
            allowClear={AddedBy ? true : false}
            placeholder="Amount Type"
            value={AddedBy ? AddedBy : "Source"}
            onChange={(val) => handleAmountTypeChange(val)}
          >
            <Option value={"1"}>Automatic</Option>
            <Option value={"2"}>Manual</Option>
          </Select>
          <Select
            className="mr-3"
            style={{ width: "30%" }}
            value={searchYear ? searchYear : "Select Year"}
            allowClear={searchYear ? true : false}
            onChange={(year) => handleChangeYear(year)}
            disabled={startDateRange && endDateRange}
          >
            {arrayOfYear.map((item, index) => (
              <Option key={index} value={item}>
                {item}
              </Option>
            ))}
          </Select>
          <Select
            className="mr-3"
            style={{ width: "30%" }}
            value={searchMonth ? statusMon(searchMonth) : "Select Month"}
            allowClear={searchMonth ? true : false}
            onChange={(year, element) => handleChangeMonth(element)}
            disabled={startDateRange && endDateRange}
          >
            {arrayOfMonths.map((item, index) => (
              <Option key={index} value={item}>
                {item}
              </Option>
            ))}
          </Select>
        </div>
        <div className="ml-2">
          <RangePicker
            id="daterangepicker"
            disabledDate={disabledEndDate}
            onChange={handleRangePicker}
            className="rang select_year_cal rang_pick"
            value={[
              startDateStamp ? moment(startDateStamp) : "",
              endDateStamp ? moment(endDateStamp) : "",
            ]}
          />
        </div>
      </div>

      {contactList ? (
        <div className="table-responsive mt-3 proj_detail">
          <table className="table table-bordered">
            <thead className="white bg_blue">
              <tr>
                <th scope="col">S.No</th>
                <th scope="col">
                  Client Name{" "}
                  <i
                    className={
                      sortType == SORT_TYPE_CONSTANTS.Asscending &&
                        sortField == "name"
                        ? "fa fa-sort-alpha-asc ml-2"
                        : "fa fa-sort-alpha-desc ml-2"
                    }
                    onClick={() => handleSort("name")}
                  ></i>
                </th>
                <th>
                  Add By
                  <i
                    className={
                      sortType == SORT_TYPE_CONSTANTS.Asscending &&
                        sortField == "addedByName"
                        ? "fa fa-sort-alpha-asc ml-2"
                        : "fa fa-sort-alpha-desc ml-2"
                    }
                    onClick={() => handleSort("addedByName")}
                  ></i>
                </th>
                <th scope="col">Email</th>
                <th>Phone Number</th>
                <th>Country</th>
                <th>City</th>
                <th>
                  Date & Time
                  <i
                    className={
                      sortType == SORT_TYPE_CONSTANTS.Asscending &&
                        sortField == "date"
                        ? "fa fa-sort-numeric-asc ml-2"
                        : "fa fa-sort-numeric-desc ml-2"
                    }
                    onClick={() => handleSort("date")}
                  ></i>
                </th>
                <th>Source</th>
                <th>Page Origin</th>
                <th style={{ minWidth: 320 }}>Project Description</th>
                <th style={{ minWidth: 320 }}>Comment</th>
                <th style={{ minWidth: 120 }}>
                  Budget
                  <i
                    className={
                      sortType == SORT_TYPE_CONSTANTS.Asscending &&
                        sortField == "maxBudget"
                        ? "fa fa-sort-numeric-asc ml-2"
                        : "fa fa-sort-numeric-desc ml-2"
                    }
                    onClick={() => handleSort("maxBudget")}
                  ></i>
                </th>
                <th>
                  Assigned BD{" "}
                  <i
                    className={
                      sortType == SORT_TYPE_CONSTANTS.Asscending &&
                        sortField == "name"
                        ? "fa fa-sort-alpha-asc ml-2"
                        : "fa fa-sort-alpha-desc ml-2"
                    }
                    onClick={() => handleSort("assignedTo")}
                  ></i>
                </th>
                <th>Contact Status</th>
                <th>Contact Result</th>

                <th style={{ minWidth: 120 }}>Action</th>
              </tr>
            </thead>
            {!_.isEmpty(contactList)
              ? contactList.map((element, index) => {
                return (
                  <tbody>
                    <tr>
                      <td>{index + 1 + (pageNo - 1) * count}</td>
                      <td>{element.name}</td>
                      <td>
                        {element.addedByFirstName || element.addedByLastName
                          ? element.addedByFirstName +
                          " " +
                          element.addedByLastName
                          : "_"}
                      </td>
                      <td>{element.email}</td>
                      <td>
                        {element.contactNumber ? element.contactNumber : "_"}
                      </td>
                      <td>{element.country ? element.country : "_"}</td>
                      <td>{element.city ? element.city : "_"}</td>
                      <td>
                        {new Date(element.created).toLocaleDateString()}{' '}
                        {new Date(element.created).toLocaleTimeString('en-US', {
                          hour: '2-digit',
                          minute: '2-digit',
                          hour12: true,
                        })}
                      </td>

                      <td>{addedByValue(element.addedBy.toString())}</td>
                      <td>{element?.pageOrigin}</td>
                      <td>
                        {
                          <div
                            dangerouslySetInnerHTML={createMarkup(
                              element.projectDetails
                                ? element.projectDetails.substr(0, 100)
                                : ""
                            )}
                          ></div>
                        }
                        ...
                        {
                          <i
                            className="fa fa-eye blue d-flex justify-content-space-between"
                            aria-hidden="true"
                            title="Project Description"
                            onClick={() =>
                              handleOpenProjectDescription(
                                element.projectDetails
                              )
                            }
                          ></i>
                        }
                      </td>
                      <td>
                        {
                          <div
                            dangerouslySetInnerHTML={createMarkup(
                              element.comment
                                ? element.comment.substr(0, 100)
                                : ""
                            )}
                          ></div>
                        }
                        {element.comment && element.comment !== "<p></p>\r\n"
                          ? "..."
                          : ""}
                        {element.comment &&
                          element.comment !== "<p></p>\r\n" ? (
                          <i
                            className="fa fa-eye blue d-flex justify-content-space-between"
                            aria-hidden="true"
                            title="Project Description"
                            onClick={() => handleComment(element.comment)}
                          ></i>
                        ) : (
                          ""
                        )}
                      </td>
                      <td>
                        {element.projectAmountMin || element.projectAmountMax
                          ? element.projectAmountMin +
                          "-" +
                          element.projectAmountMax +
                          "$"
                          : "_"}
                      </td>
                      <td>
                        {element.assignedFirstName || element.assignedLastName
                          ? element.assignedFirstName +
                          " " +
                          element.assignedLastName
                          : "Unassigned"}
                      </td>
                      <td>{contactStatusValue(element?.contactStatus)}</td>
                      <td>{contactResultValue(element?.contactResult)}</td>
                      <td>
                        <span
                          onClick={() => handleOpenEditClick(element, index)}
                        >
                          <i
                            className="fa fa-pencil"
                            aria-hidden="true"
                            title="Edit Contact"
                          ></i>
                        </span>
                        {localStorage.getItem("isAdmin") == 1 ? (
                          <>
                            <span
                              onClick={() =>
                                handleOpenAssignClick(
                                  salesList,
                                  element,
                                  index
                                )
                              }
                              className="ml-3"
                            >
                              <i
                                className="fa fa-hand-pointer-o"
                                aria-hidden="true"
                                title="Assign Contact"
                              ></i>
                            </span>

                            <span
                              onClick={() =>
                                handleOpenDeleteContactPopup(element._id)
                              }
                              className="ml-3"
                            >
                              <i
                                className="fa fa-trash-o red"
                                aria-hidden="true"
                                title="Delete Contact"
                              ></i>
                            </span>
                            {console.log(element?.UpdatedHistory,"eknewlenth")}
                            {Array.isArray(element?.UpdatedHistory) && element?.UpdatedHistory.length > 0? <span
                              onClick={() =>
                                handleViewUpdatedHistory(element?.UpdatedHistory)
                              }
                              className="ml-3"
                            >
                              <div style={{ width: '14px', height: '14px', cursor: 'pointer' }}>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                  <title>View Updated History</title>
                                  <path d="M75 75L41 41C25.9 25.9 0 36.6 0 57.9L0 168c0 13.3 10.7 24 24 24l110.1 0c21.4 0 32.1-25.9 17-41l-30.8-30.8C155 85.5 203 64 256 64c106 0 192 86 192 192s-86 192-192 192c-40.8 0-78.6-12.7-109.7-34.4c-14.5-10.1-34.4-6.6-44.6 7.9s-6.6 34.4 7.9 44.6C151.2 495 201.7 512 256 512c141.4 0 256-114.6 256-256S397.4 0 256 0C185.3 0 121.3 28.7 75 75zm181 53c-13.3 0-24 10.7-24 24l0 104c0 6.4 2.5 12.5 7 17l72 72c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-65-65 0-94.1c0-13.3-10.7-24-24-24z" />
                                </svg>
                              </div>
                            </span> : <></>}
                          </>
                        ) : (
                          ""
                        )}
                      </td>
                    </tr>
                  </tbody>
                );
              })
              : ""}
          </table>
          {contactList && contactList.length > 0 ? (
            ""
          ) : (
            <h3 className="text-center">No data available</h3>
          )}
        </div>
      ) : (
        <div className="loaderDiv">
          <DotLoader />
        </div>
      )}
      {dataCount > 10 ? (
        <Pagination
          activePage={pageNo}
          itemsCountPerPage={count}
          totalItemsCount={dataCount}
          pageRangeDisplayed={3}
          onChange={handlePageChange}
        />
      ) : null}

      {/* project Description Model */}
      <AntModal
        centered
        className="attend_modal"
        footer={false}
        visible={projectDescriptionModal}
        onCancel={closeModal1}
      >
        <Modal.Body>
          <div className={`assign_device`}>
            <h3 className="mb-4">Project Description</h3>
            <div className="cm_overflow_device">
              <div
                dangerouslySetInnerHTML={createMarkup(
                  projectDescriptionDeatail
                )}
              ></div>

              {/* <p>{projectDescriptionDeatail}</p> */}
            </div>
          </div>
        </Modal.Body>
      </AntModal>

      {/* Comment Modal */}

      <AntModal
        centered
        className="attend_modal"
        footer={false}
        visible={commentModal}
        onCancel={closeModal1}
      >
        <Modal.Body>
          <div className={`assign_device`}>
            <h3 className="mb-4">Comment</h3>
            <div className="cm_overflow_device">
              <div dangerouslySetInnerHTML={createMarkup(comment)}></div>
            </div>
          </div>
        </Modal.Body>
      </AntModal>

      {/* Assing Contact to Employee Module */}

      <AntModal
        centered
        className="attend_modal"
        footer={false}
        visible={assignContactModal}
        onCancel={closeModal1}
      >
        <Modal.Body>
          <h3 className="mb-4">ASSIGN CONTACT</h3>
          <div className="row">
            <div
              className={`form-group col-12 ${employeeIdErrorMsg ? "input_error" : ""
                }`}
            >
              <label className="cm_bold">Employee Name</label>

              <Select
                showSearch
                name="employeeId"
                value={employeeId ? employeeId : " Select Employee"}
                defaultValue="Select Name"
                onChange={(e) => handleSelectEmployeeChange(e)}
                onSearch={() => handleEmployeeSearch}
                optionFilterProp="key"
                filterOption={(input, option) =>
                  option.key.toLowerCase().includes(input.toLowerCase())
                }
              // disabled={miniLoader}
              >
                {!_.isEmpty(salesList)
                  ? salesList
                    .sort(function (a, b) {
                      if (a.firstName < b.firstName) {
                        return -1;
                      }
                      if (a.firstName > b.firstName) {
                        return 1;
                      }
                      return 0;
                    })
                    .map((employee) => (
                      <Option
                        key={`${employee.firstName} ${employee.lastName}`}
                        value={employee._id}
                      >
                        <span>
                          {employee.firstName} {employee.lastName} (
                          {employee.empId})
                        </span>
                      </Option>
                    ))
                  : ""}
              </Select>
              {employeeIdErrorMsg ? (
                <span className="error_msg">{employeeIdErrorMsg}</span>
              ) : null}
            </div>
          </div>
          <div className="addCandidateButtonsDiv">
            {/* {miniLoader ?
                            <div className="cover-spin1"></div>
                            : ''} */}

            {miniLoader ? (
              <DotLoader />
            ) : (
              <button
                type="submit"
                onClick={() => onSubmit()}
                className=" btn btn-primary  buttonWidth"
                disabled={miniLoader}
              >
                Save
              </button>
            )}
            <button
              type="button"
              onClick={closeModal1}
              className="cancel_btn btn buttonWidth"
              disabled={miniLoader}
            >
              Cancel
            </button>
          </div>
        </Modal.Body>
      </AntModal>
      {/* delete Contact */}

      <AntModal
        centered
        className="attend_modal"
        footer={false}
        visible={openDeleteContactPopup}
        onCancel={closeModal1}
      >
        <Modal.Body>
          <h3 className="mb-4 text-center">Do you want to delete contact ?</h3>
          <div className="submit_button_margin mt-4">
            {miniLoader ? (
              <DotLoader />
            ) : (
              <button
                type="submit"
                onClick={() => handleDeleteContactClick()}
                className=" btn btn-danger  buttonWidth"
                disabled={miniLoader}
              >
                Delete
              </button>
            )}
            <button
              type="button"
              onClick={closeModal1}
              className="cancel_btn btn buttonWidth"
              disabled={miniLoader}
            >
              Cancel
            </button>
          </div>
        </Modal.Body>
      </AntModal>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    contactList: state.contactList.data.result,
    salesList: state.contactList.data.salesList,
    dataCount: state.contactList.data.dataCount,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getContactList: (query) => {
      dispatch(getContactList(query));
    },
    deleteContactByAdmin: (
      data,
      setResDeleteMsg,
      setResFailMsg,
      getData,
      setMiniLoader,
      closeModal1
    ) => {
      dispatch(
        deleteContactByAdmin(
          data,
          setResDeleteMsg,
          setResFailMsg,
          getData,
          setMiniLoader,
          closeModal1
        )
      );
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Contact);

import React from 'react';
// import { Table } from 'antd';
import { Modal as AntModal, Table } from "antd";
import Modal from "react-bootstrap/Modal";
import {
    contactResultValue,
    contactStatusValue,
} from "./ContactConstants";


const createMarkup = (html) => ({ __html: html });
const ViewUpdatedHistory = ({ showViewUpdatedHistoryModal, setShowViewUpdatedHistoryModal, state }) => {
    // Define table columns
    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: 'Contact Number',
            dataIndex: 'contactNumber',
            key: 'contactNumber',
        },
        {
            title: 'File URL',
            dataIndex: 'fileLink',
            key: 'fileLink',
        },
        {
            title: 'Project Details',
            dataIndex: 'projectDetails',
            key: 'projectDetails',
            render: (text) => <div dangerouslySetInnerHTML={createMarkup(text)} />
        },
        {
            title: 'Contact Status',
            dataIndex: 'contactStatus',
            key: 'contactStatus',
            render: (value) => {
                const statusMap = {
                    1: "Hot",
                    2: "Warm",
                    3: "Cold",
                    4: "Not Relevant",
                    0: ""
                };
                return statusMap[value];
            },
        },
        {
            title: 'Contact Result',
            dataIndex: 'contactResult',
            key: 'contactResult',
            render: (value) => {
                const statusMap = {
                    1: "Win",
                    2: "Loss",
                    3: "Contact In Future",
                    4: "Connection Not Establised",
                    0: ""
                };
                return statusMap[value];
            },
        },
        {
            title: 'Comment',
            dataIndex: 'comment',
            key: 'comment',
            render: (text) => <div dangerouslySetInnerHTML={createMarkup(text)} />
        },
        {
            title: 'Project Amount Min',
            dataIndex: 'projectAmountMin',
            key: 'projectAmountMin',
        },
        {
            title: 'Project Amount Max',
            dataIndex: 'projectAmountMax',
            key: 'projectAmountMax',
        },
        {
            title: 'Updated',
            dataIndex: 'updated',
            key: 'updated',
            render: (text) => new Date(text).toLocaleString(), // Format date
        },
    ];
    const handleCancelButtonClick = () => {
        setShowViewUpdatedHistoryModal(false);
    }

    return (
        <AntModal
            centered
            className="attend_modal"
            footer={false}
            visible={showViewUpdatedHistoryModal}
            onCancel={handleCancelButtonClick}
        >
            <Modal.Body>
                <Table
                    dataSource={state}
                    columns={columns}
                    pagination={false}
                // rowKey="_id" // Use `_id` as the unique key for each row
                // pagination={{ pageSize: 5 }} // Optional: Enable pagination with 5 rows per page
                />
            </Modal.Body>
        </AntModal>

    );
};

export default ViewUpdatedHistory;
